<template>
  <div style="margin-top:60px;">
      <v-dialog v-model="RelatedRecordFormDialog" fullscreen>
<!--     
      <v-layout row class="justify-center"> -->
        <v-card height ="100%" width="100%">
          <v-card-title class="headline blue-grey darken-2 white--text">
            <v-btn fab @click="CancelRelatedRecordFormDialog()"><v-icon color="red" x-large>mdi-window-close</v-icon>
            </v-btn><v-btn fab @click="SaveAutomationRelated()" icon>
            <v-icon color="green" x-large>mdi-content-save</v-icon></v-btn>
            ({{EditedAutomationAction.MethodName}}) {{RelatedRecord.DisplayName}} - Field Configuration</v-card-title>
              <v-navigation-drawer
                absolute
                right
                v-model="fielddrawer"
              >
                <template v-slot:prepend >
                  <v-list-item dark two-line class="headline blue-grey darken-2 white--text">
                    
                    <v-list-item-avatar>
                      <v-icon @click="fielddrawer = !fielddrawer">mdi-eye-off-outline</v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title class="red--text"><strong>{{FieldFilter.DisplayName}}</strong></v-list-item-title>
                      <v-list-item-subtitle>Input Configuration</v-list-item-subtitle>
                      
                      <v-select @change="RemoveFieldInputs()" clearable :items="FieldInputOptions" v-model="FieldFilter.InputType"></v-select>
                    </v-list-item-content>
                    <v-btn left bottom fab icon @click="AddAutomationFieldInput()" v-if="FieldFilter.InputType === 'Combined Value'"><v-icon>mdi-plus-thick</v-icon></v-btn>
                  </v-list-item>
                </template>

                <v-divider></v-divider>
                <v-list dense v-if="FieldFilter && FieldFilter.InputType === 'Custom Value'">
                  <v-list-item
                    v-if="FieldFilter.Type === 'Boolean' && FieldFilter.Input"
                    class="fieldmenuitem">
                    <v-list-item-content>
                      <v-switch label="True or False"  v-model="FieldFilter.Input[0].Value"></v-switch>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-list dense v-if="FieldFilter.InputType === 'Combined Value'">
                  <v-list-item
                    v-for="(item,i) in FieldFilter.Input"
                    :key="item.itemObjKey"
                    class="fieldmenuitem"

                  >
                   

                    <v-list-item-content>
                      <v-list-item-title>{{ item.DisplayName }}</v-list-item-title>

                    </v-list-item-content>
                    <v-list-item-icon @click="ActivateAutomationFieldDialog(item,i)">
                      <v-icon  class="spin">mdi-cogs</v-icon>
                    </v-list-item-icon>
                  </v-list-item>

                </v-list>
                <v-list dense v-if="FieldFilter.InputType === 'Calculated Value'">
                  <v-list-item class="fieldmenuitem">                  
                    <v-list-item-content>
                      <v-select label="Output Type" v-model="FieldFilter.CalculatedOutputType" :items="['String','Number','Date']"></v-select>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
                <v-list dense v-if="FieldFilter.InputType === 'Calculated Value' && FieldFilter.CalculatedOutputType">
                    <v-btn @click="AddAutomationFieldInput()" v-if="FieldFilter.InputType === 'Calculated Value'">Add Field</v-btn>
                  <v-list-item
                    v-for="(item,i) in FieldFilter.Input"
                    :key="item.itemObjKey"
                    class="fieldmenuitem"
                  >
                   

                    <v-list-item-content>
                      <v-list-item-title>{{ item.DisplayName }}</v-list-item-title>
                  
                    </v-list-item-content>
                    <v-list-item-icon v-if="FieldFilter.Type !== 'Date'" @click="ActivateCalculatedNumberFieldMapDialog(item,i)">
                      <v-icon  class="spin">mdi-cogs</v-icon>
                    </v-list-item-icon>
                    <v-list-item-icon v-if="FieldFilter.Type === 'Date'" @click="ActivateCalculatedDateFieldMapDialog(item,i)">
                      <v-icon  class="spin">mdi-cogs</v-icon>
                    </v-list-item-icon>
                  </v-list-item>

                </v-list>
                <v-dialog v-model="AutomationFielddialog">
                  <v-card>
                    <v-card-title>{{AutoFieldeditItem.DisplayName}}</v-card-title>
                    <!-- <p>{{FieldFilter}}</p> -->
                    <v-select :items="['Custom Text', 'Parent Field']" clearable label ="Input Type" v-model="AutoFieldeditItem.MapType"></v-select>
                   
                    <span v-if="AutoFieldeditItem.MapType === 'Parent Field'">
                     
                      <v-select label="Field" :items="RelatedFields" item-text="sourcename" v-model="AutoFieldeditItem.ParentInput" @change="AssignFieldOutput()" return-object></v-select></span>
                    <span v-if="AutoFieldeditItem.MapType === 'Custom Text'"><v-text-field v-model="AutoFieldeditItem.DisplayName"></v-text-field></span>
                    </v-card>
                  </v-dialog>
                <v-list dense v-if="FieldFilter.InputType === 'Parent Field'">
                  <v-list-item>

                    <v-list-item-icon>
                      <v-icon >mdi-database</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                       <v-select :items="AllCurrentBuildRelatedBuilds" item-text="Main" clearable label="Source Record" v-model="FieldFilter.source" return-object></v-select>
                    

                    </v-list-item-content>

                  </v-list-item>
                  <v-list-item>

                    <v-list-item-icon>
                      <v-icon >mdi-text-recognition</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      
                      <v-select v-if="FieldFilter.source" :items="FieldFilterRelevantFields" clearable label="Source Field" item-text="DisplayName" v-model="TempParentInput" @change="AssignFieldOutput()" return-object></v-select>

                    </v-list-item-content>

                  </v-list-item>

                </v-list>
              </v-navigation-drawer>
              <!-- <v-container> -->
                <v-row v-if="EditedAutomationAction.ActionType === 'Update'">
                  Update State?
                </v-row>
                <v-row v-if="RelatedRecordState && EditedAutomationAction.ActionType === 'Update'">
                  <v-select v-model="EditedAutomationAction.StatusUpdate" :items="RelatedRecordState.Options" return-object item-text="Name" label="Status"/>
                  <v-select v-if="EditedAutomationAction.StatusUpdate" :items="EditedAutomationAction.StatusUpdate.Options" return-object item-text="Name"
                  v-model="EditedAutomationAction.StatusReasonUpdate" label="Status Reason"/>
                </v-row>
              <div v-for="(tab,i) in RelatedComponentTabs" :key="tab.itemObjKey">
              <h3 class="mx-10">{{tab.Name}}</h3>
              <v-layout row>
                
              <v-row>
                <v-col v-for="(section, itemObjKey) in tab.Elements" :key="section.itemObjKey"><br>
                  <v-card outlined>
                      <v-card flat>
                        <v-card-subtitle v-for="(field, index) in section.Fields" :key="field.itemObjKey">
                          <v-layout row>
                            <v-col>
                              <v-row>
                                <v-card outlined width="100%" color="grey lighten-3">
                                <div @click="AssignFieldFilter(tab, i, section, itemObjKey, field, index),ActivateFieldDrawer()"><h4>{{field.DisplayName}}</h4><h2 v-if="field.Primary">{{field.Output}}</h2><h3 v-else-if="field.Secondary">{{field.Output}}</h3><p v-else-if="!field.Primary && !field.Secondary && !field.FeaturedPhoto">{{field.Output}}</p></div>
                                <img v-if="field.FeaturedPhoto" :src="require('@/assets/ImageHolder.png')" height="150px">
                              </v-card>
                              </v-row>
                            </v-col>
                          </v-layout>
                          </v-card-subtitle>
                        </v-card>
                    </v-card>
                  </v-col>
              </v-row>

              </v-layout>
              </div>
              <!-- </v-container> -->
            </v-card>
      <!-- </v-layout> -->
    </v-dialog>
    <v-card flat class="transparent" height="100%" width="100%" tile>
 
    
   <v-dialog v-model="CalculatedDateFieldMapDialog" width="800px">
        <v-card class="padded">
            <v-card-title class="headline blue-grey darken-2 white--text">Configure Calculated Field - {{FieldFilter.DisplayName}}<v-spacer></v-spacer><v-btn fab @click="SaveCalculatedDateFieldMap()" icon><v-icon color="green" x-large>mdi-content-save</v-icon></v-btn><v-btn fab @click="CancelCalculatedDateFieldMapDialog()"><v-icon color="red" x-large>mdi-window-close</v-icon></v-btn></v-card-title>
            <v-card-text>
              Here you can configure how the {{FieldFilter.DisplayName}} is calculated. Below you have 3 panels, but most of the time you will only use 1 of them, either one or the other. If you configured under "Day Calculation" to add 365 days, the calculated will advance 1 year. Only for VERY complex scenarios, you would make use of more than one at a time.          
            </v-card-text>
            <v-select :items="['Processing Date','Date Field']" @change="ToggleCalculatedDateFieldMapDialog()" v-model="FieldFilter.DateSource" label="Date Source"></v-select>
              <v-select v-if="FieldFilter.DateSource && FieldFilter.DateSource !== 'Processing Date'" label="field"  @change="ToggleCalculatedDateFieldMapDialog()" :items="OnlyDateFields" item-text="sourcename" v-model="FieldFilter.StartingDate" return-object></v-select>
            <v-expansion-panels>
              <v-expansion-panel v-for="config in CalculatedDateConfiguration" :key="config.itemObjKey">
                <v-expansion-panel-header>{{config.Name}}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-checkbox :label="config.BooleanLabel" v-model="FieldFilter[config.Boolean]" @change="ToggleDateProperty(config.Boolean,config.ObjectName)"></v-checkbox>
                 
                  <v-list v-if="FieldFilter[config.ObjectName] && FieldFilter.StartingDate || FieldFilter[config.ObjectName] && FieldFilter[config.ObjectName].DateSource === 'Processing Date'">
                    <v-btn @click="AddCalcDateFieldFormula(FieldFilter[config.ObjectName])">Add</v-btn>
                    <v-list-item v-for="formula in FieldFilter[config.ObjectName].Integers" :key="formula.itemObjKey">
                        <v-list-item-content>
                          <v-select :items="['+','-','*','/','=']" v-model="formula.operation" @change="CheckOperator(formula.operation),ToggleCalculatedDateFieldMapDialog()"></v-select>
                          </v-list-item-content>
                        <v-list-item-content>
                          <v-select :items="['integer','field']" @change="ToggleCalculatedDateFieldMapDialog()" v-model="formula.input" label="input"></v-select>
                          </v-list-item-content>
                          <v-list-item-content>
                          <v-select  label="field"  @change="ToggleCalculatedDateFieldMapDialog()" v-if="formula.input === 'field'" :items="OnlyNumberFields" item-text="sourcename" v-model="formula.fieldName" return-object></v-select>
                          </v-list-item-content>
                        <v-list-item-content>
                          <v-text-field  label="number" type="number" v-if="formula.input==='integer'" v-model="formula.integer"></v-text-field>
                          </v-list-item-content>
                      </v-list-item>
                    </v-list>
                </v-expansion-panel-content>                
              </v-expansion-panel>            
            </v-expansion-panels>
        </v-card>
    </v-dialog>
    <v-dialog v-model="CalculatedNumberFieldMapDialog" width="800px">
        <v-card class="padded">
            <v-card-title class="headline blue-grey darken-2 white--text">Configure Calculated Field - {{FieldFilter.DisplayName}}<v-spacer></v-spacer><v-btn fab @click="SaveCalculatedNumberFieldMap()" icon><v-icon color="green" x-large>mdi-content-save</v-icon></v-btn><v-btn fab @click="CancelCalculatedNumberFieldMapDialog()"><v-icon color="red" x-large>mdi-window-close</v-icon></v-btn></v-card-title>
            <v-card-text>
                <v-list>
                    <v-btn @click="AddCalcFieldFormula()">Add</v-btn>
                    <v-list-item v-for="formula in FieldFilter.FieldFormulas" :key="formula.itemObjKey">
                        <v-list-item-content>
                          <v-select :items="['integer','field']" @change="ToggleCalculatedNumberFieldMapDialog()" v-model="formula.input" label="input"></v-select>
                          </v-list-item-content>
                          <v-list-item-content>
                          <v-select  label="field"  @change="ToggleCalculatedNumberFieldMapDialog()" v-if="formula.input === 'field'" :items="DatesandNumberFields" item-text="sourcename" v-model="formula.fieldName" return-object></v-select>
                          </v-list-item-content>
                        <v-list-item-content>
                          <v-text-field  label="number" type="number" v-if="formula.input==='integer'" v-model="formula.integer"></v-text-field>
                         
                          </v-list-item-content>
                          <v-list-item-content>
                          <v-checkbox label="add operator" v-model="formula.hasoperation"></v-checkbox>
                          </v-list-item-content>
                          <v-list-item-content>
                          <v-select :items="['+','-','*','/','=']" v-model="formula.operation" @change="CheckOperator(formula.operation),ToggleCalculatedNumberFieldMapDialog()"></v-select>
                          </v-list-item-content>
                      </v-list-item>
                    </v-list>
            </v-card-text>
        </v-card>
    </v-dialog>
    <v-dialog v-model="AutomationDialog">
         <v-card class="padded">
             <v-img src='@/assets/RapidappsParallax.jpeg'>
             <v-card-title  class="headline blue-grey darken-2 white--text"><v-btn fab @click="CancelAutomationDialog()"><v-icon color="red" x-large>mdi-window-close</v-icon></v-btn><v-btn fab @click="SaveAutomation()" icon><v-icon color="green" x-large>mdi-content-save</v-icon></v-btn> Edit Automation
             </v-card-title>
             <v-card-title><v-text-field v-model="EditedAutomation.Name" label="Name"></v-text-field></v-card-title>
             
            <v-tabs background-color="accent" dark centered>
                <v-tab>Trigger</v-tab>        
                <v-tab-item>
                    <v-card min-height="400px">
                        <v-card-title>
                            Trigger Configuration
                        </v-card-title>
                        
                        <v-list  class="metal linear" >
                        <v-list-item>
                            <v-select label="Trigger" :items="['Create','Update','Scheduled','Strictly Pipeline']" v-model="EditedAutomation.Trigger"></v-select>
                        </v-list-item>
                        <v-list-item>
                            <v-combobox :items="AllFields" item-text="DisplayName" v-if="EditedAutomation.Trigger === 'Update'" label="Trigger" v-model="EditedAutomation.UpdateField"></v-combobox>
                        </v-list-item>
                        <v-list-item>
                             <v-select v-if="EditedAutomation.Trigger === 'Scheduled'" label="Schedule Frequency" :items="['Daily','Weekly','Monthly']" v-model="EditedAutomation.ScheduleFrequency"></v-select>
                        </v-list-item>
                        </v-list>
                    </v-card>
                </v-tab-item>
                <v-tab>Conditions</v-tab>        
                <v-tab-item>
                    <v-card class="padded" min-height="400px">
                        
                        <v-card-title>
                            Conditions and Configuration
                        </v-card-title>
                        <v-btn @click="AddCondition()">Add Condition</v-btn>
                        <v-expansion-panels>
                            
                  <v-expansion-panel v-for="step in EditedAutomation.Conditions" :key="step.itemObjKey" @click="AssignApprovalActionMethodsCondition(step)">
                    <v-expansion-panel-header>{{step.Name}}<v-layout class="justify-end"><v-icon color="red" v-if="step.ResultAction === 'CANCEL'">mdi-cancel</v-icon><v-icon color="orange" v-if="step.ResultAction === 'OTHER'">mdi-routes</v-icon><v-icon color="green" v-if="step.ResultAction === 'PROCESS APPROVAL'">mdi-marker-check</v-icon></v-layout></v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-checkbox label="Can call from Button" v-model="step.CallfromButton"></v-checkbox>
                      <v-text-field v-if="step.CallfromButton" label="Button Text" v-model="step.ApprovalButtonText"></v-text-field>
                      <v-btn v-if="step.CallfromButton">{{step.ApprovalButtonText}}</v-btn>
                      
                      <v-text-field label="Name" v-model="step.Name"></v-text-field>
                          <v-combobox label="Select Action(s)" :items="ShortenedAutomationActions" item-text="MethodName" v-model="step.ResultActions" size="1" multiple></v-combobox>
                      <v-btn absolute top right @click="AddConditionCheck(step,'Condition')">Add Check</v-btn>
                     
                        <div  v-if="step.Steps && step.Steps.length>0">
                        <v-list dense class="my-1 mx-1 basicoutlined" v-for="andstep in step.Steps" :key ="andstep.itemObjKey">  
                   
                      <v-list-item>
                        
                        <v-list-item-content>
                          {{andstep.StepNumber}}
                          </v-list-item-content>
                          <v-list-item-content>
                          
                          <v-select @change="RefreshAutoDialog()"  label="Condition" :items="['IF','ALWAYS']" v-model="andstep.Condition" size="1"></v-select>
                          </v-list-item-content>
                          <v-list-item-content v-if="andstep.Condition">
                              <v-combobox @change="RefreshAutoDialog()" :items="AllCurrentBuildRelatedBuilds" item-text="Main" return-object label="Record" v-model="andstep.Record"></v-combobox>
                              </v-list-item-content>
                      </v-list-item>
                      <v-list-item v-if="andstep.Condition !== 'ALWAYS'">
                        <v-list-item-content v-if="andstep.Record">
                          <v-select  label="Field" :items="andstep.Record.AllFields" item-text="DisplayName" v-model="andstep.FieldtoCheck" size="1" return-object></v-select>

                          </v-list-item-content> 
                          
                          <v-list-item-content v-if="andstep.FieldtoCheck && andstep.FieldtoCheck.Type !== 'Date' && andstep.FieldtoCheck.Type !== 'Lookup'">
                          <v-select  v-if="andstep.FieldtoCheck && andstep.FieldtoCheck.Type === 'Single Line Text' || andstep.FieldtoCheck && andstep.FieldtoCheck.Type === 'Multiple Lines Text'" label="Parameter" :items="['===','!==','includes','does not include','Contains Data','Does Not Contain Data']" v-model="andstep.Parameter" size="1"></v-select>
                          <v-select  v-if="andstep.FieldtoCheck && andstep.FieldtoCheck.Type === 'Boolean'" label="Parameter" :items="['true','false']" v-model="andstep.Parameter" size="1"></v-select>
                          <v-select  v-if="andstep.FieldtoCheck && andstep.FieldtoCheck.Type === 'Single File Upload'" label="Parameter" :items="['Contains Data','Does Not Contain Data']" v-model="andstep.Parameter" size="1"></v-select>
                          <v-select  v-if="andstep.FieldtoCheck && andstep.FieldtoCheck.Type === 'Option Set' || andstep.FieldtoCheck && andstep.FieldtoCheck.Type === 'Global Option Set'" label="Parameter" :items="['===','!==','includes','does not include','Contains Data','Does Not Contain Data']" v-model="andstep.Parameter" size="1"></v-select>
                          <v-select  v-if="andstep.FieldtoCheck && andstep.FieldtoCheck.Type === 'Number' || andstep.FieldtoCheck && andstep.FieldtoCheck.Type === 'Calculated Field'" label="Parameter" :items="['===','!==','<','>','=>','<=','Contains Data','Does Not Contain Data']" v-model="andstep.Parameter" size="1"></v-select>
                          <v-select  v-if="andstep.FieldtoCheck && andstep.FieldtoCheck.Type === 'Date'" :items="['On','Not On','On or After','On or Before']" v-model="andstep.Parameter" size="1"></v-select>
                          </v-list-item-content>
                          <v-list-item-content v-if="andstep.Parameter && andstep.FieldtoCheck.Type !== 'Date' && andstep.FieldtoCheck.Type !== 'Lookup' && andstep.FieldtoCheck.Type !== 'Boolean'">
                          <v-select label="Value" item-text="Name" return-object v-if="andstep.FieldtoCheck.Type === 'Option Set' || andstep.FieldtoCheck && andstep.FieldtoCheck.Type === 'Global Option Set'" :items="andstep.FieldtoCheck.Options" v-model="andstep.Value"></v-select>
                          <v-text-field label="Value" v-if="andstep.FieldtoCheck && andstep.FieldtoCheck.Type !== 'Option Set'  && andstep.FieldtoCheck.Type !== 'Global Option Set' && andstep.Parameter !== 'Contains Data' && andstep.Parameter !== 'Does Not Contain Data'" v-model="andstep.Value"></v-text-field>
                          </v-list-item-content>
                          <v-list-item-content v-if="andstep.Parameter && andstep.FieldtoCheck && andstep.FieldtoCheck.Type === 'Boolean'">
                          <v-switch v-if="andstep.Parameter === 'true'" :value="true" readonly filled></v-switch>
                          <v-switch  v-if="andstep.Parameter === 'false'" :value="false" readonly filled></v-switch>
                          </v-list-item-content>
                          <v-list-item-content v-if="andstep.FieldtoCheck && andstep.FieldtoCheck.Type === 'Date'">
                            <v-select label="Date Check Type" :items="['Contains Data','Does Not Contain Data','Specific Date','Preset Options','Other Field','STD Date']" v-model="andstep.DateParameter"></v-select>
                          </v-list-item-content>
                          <v-list-item-content v-if="andstep.FieldtoCheck && andstep.FieldtoCheck.Type === 'Lookup' && andstep.FieldtoCheck.RelatedBuildID !== RouteID">
                            <v-select label="Lookup Check Type" :items="['Preset Options','Other Field']" v-model="andstep.LookupParameter"></v-select>
                          </v-list-item-content>  
                          <v-list-item-content v-if="andstep.FieldtoCheck && andstep.FieldtoCheck.Type === 'Lookup' && andstep.FieldtoCheck.RelatedBuildID === RouteID">
                            <v-select label="Lookup Check Type" :items="['Preset Options','Other Field','Current Record']" v-model="andstep.LookupParameter"></v-select>
                          </v-list-item-content>                        
                          <v-list-item-content v-if="andstep.DateParameter && andstep.FieldtoCheck.Type && andstep.FieldtoCheck.Type === 'Date'">
                          <v-select  v-if="andstep.DateParameter === 'Other Field' || andstep.DateParameter === 'STD Date'" :items="['===','!==','On or After','On or Before','Older Than','Later Than','Same Calendar Year','Same Fiscal','Same Fiscal Period']" v-model="andstep.Parameter" size="1"></v-select>
                          <v-select  v-if="andstep.DateParameter === 'Specific Date'" :items="['===','!==','On or After','On or Before']" v-model="andstep.Parameter" size="1"></v-select>
                          </v-list-item-content>
                          <v-list-item-content v-if="andstep.LookupParameter && andstep.FieldtoCheck.Type && andstep.FieldtoCheck.Type === 'Lookup'">
                          <v-select  v-if="andstep.LookupParameter === 'Other Field'" :items="['===','!==','Same Business Unit','Same Owner']" v-model="andstep.Parameter" size="1"></v-select>
                          </v-list-item-content>                                           
                          <v-list-item-content v-if="andstep.FieldtoCheck && andstep.FieldtoCheck.Type === 'Date' && andstep.DateParameter && andstep.DateParameter === 'Preset Options' || andstep.DateParameter && andstep.Parameter && andstep.FieldtoCheck.Type === 'Date'">
                          <v-select label="Value" v-if="andstep.DateParameter === 'Preset Options'" :items="['This Fiscal','Last Fiscal','This Fiscal Period','Last Fiscal Period','This Calendar Year']" v-model="andstep.Value"></v-select>
                          <v-menu  v-if="andstep.DateParameter === 'Specific Date'"
                            v-model="andstep.DateMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="andstep.Value"
                                label="Date"
                                prepend-icon="mdi-calendar-month"
                                readonly
                                v-on="on">
                                </v-text-field>
                              </template>
                              <v-date-picker v-model="andstep.Value" @input="andstep.DateMenu = false"></v-date-picker>
                            </v-menu>                          
                          </v-list-item-content>
                          <v-list-item-content v-if="andstep.FieldtoCheck && andstep.FieldtoCheck && andstep.FieldtoCheck.Type === 'Lookup' && andstep.LookupParameter && andstep.LookupParameter === 'Preset Options' || andstep.LookupParameter && andstep.Parameter && andstep.FieldtoCheck.Type === 'Lookup'">
                          <v-select label="Value" v-if="andstep.LookupParameter === 'Preset Options'" :items="['Contains Data','Does Not Contain Data']" v-model="andstep.Value"></v-select>           
                          </v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="andstep.FieldtoCheck && andstep.FieldtoCheck.Type === 'Date'">
                          <v-list-item-content v-if="andstep.DateParameter === 'Other Field'">
                          <v-select label="Record" :items="AllCurrentBuildRelatedBuilds" item-text="Main" v-model="andstep.DateCheckRecord" return-object></v-select> 
                          </v-list-item-content>
                          <v-list-item-content v-if="andstep.DateParameter === 'Other Field' && andstep.DateCheckRecord" >
                          <v-select label="Value" :items="andstep.DateCheckRecord.DateFields" v-model="andstep.Value" item-text="DisplayName"></v-select>
                          </v-list-item-content>
                          <v-list-item-content v-if="andstep.DateParameter === 'STD Date'" >
                          <v-select label="Value" :items="['Today']" v-model="andstep.Value"></v-select>
                          </v-list-item-content>                    
                          </v-list-item> 
                          <v-list-item v-if="andstep.FieldtoCheck && andstep.FieldtoCheck.Type === 'Lookup'">
                          <v-list-item-content v-if="andstep.LookupParameter === 'Other Field'">
                          <v-select label="Record" :items="AllCurrentBuildRelatedBuilds" item-text="Main" v-model="andstep.LookupCheckRecord" return-object></v-select> 
                          </v-list-item-content>
                          <v-list-item-content v-if="andstep.LookupParameter === 'Other Field' && andstep.LookupCheckRecord">
                          <v-select label="Matching Lookup" :items="andstep.LookupCheckRecord.LookupFields" v-model="andstep.Value" item-text="DisplayName"></v-select>
                          </v-list-item-content>                    
                          </v-list-item>                          
                          <v-list-item>
                          <!-- <v-list-item-content v-if="andstep.Value || andstep.DateParameter === 'Contains Data' || andstep.DateParameter === 'Does Not Contain Data' || andstep.Parameter === 'Contains Data' || andstep.Parameter === 'Does Not Contain Data'">
                            <v-checkbox label="Process Action(s) Here?" v-model="andstep.TriggersAction"></v-checkbox>
                          <v-combobox v-if="andstep.TriggersAction" label="Select Action(s)" :items="EditedAutomation.AutomationActions" item-text="MethodName" v-model="andstep.ResultActions" size="1" multiple></v-combobox>
                          </v-list-item-content> -->
                          </v-list-item> 
                        </v-list>
                        </div>
                        
                        </v-expansion-panel-content>
                        </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card>
                </v-tab-item>
                <v-tab>Methods</v-tab>        
                <v-tab-item>
                    <v-card class="padded" min-height="400px">
                        <v-card-title>
                            Actions and Configuration
                        </v-card-title>
                        <v-btn @click="AddAutomationAction()">Add Action</v-btn>
                        <v-list>
                        <v-list-item v-for="action in EditedAutomation.AutomationActions" :key="action.itemObjKey">
                              <v-list class="basicoutlined">
                              <v-list-item>
                                <v-list-item-content class="blue-grey darken-2 white--text"><v-text-field v-model="action.MethodName" label ="Method Name" dark></v-text-field><v-switch dark label ="Once Off" v-model="action.OnceOff"></v-switch></v-list-item-content>
                              </v-list-item>
                              <v-list-item>
                              <v-list-item-content>
                              <v-select @change="RefreshAutoDialog()" :items="['Create','Update','Send Email']" label="Action Type" v-model="action.ActionType"></v-select>
                               </v-list-item-content>
                              <v-list-item-content>
                                
                              <v-combobox @change="RefreshAutoDialog()" v-if="action.ActionType === 'Create'" :items="SystemEntities" item-text="DisplayName" return-object label="Record" v-model="action.Record"></v-combobox>
                              <v-combobox @change="RefreshAutoDialog()" v-if="action.ActionType === 'Update'" :items="AllCurrentBuildRelatedBuilds" item-text="Main" return-object label="Record" v-model="action.Record"></v-combobox>
                              </v-list-item-content>
                              <v-list-item-content>
                              <v-icon v-if="action.ActionType === 'Send Email'" @click="ActivateApprovalEmailActionConfigurationDialog(action)">mdi-cogs</v-icon>
                              <v-icon v-if="action.ActionType === 'Create'" @click="ActivateRelatedRecordForm(action)">mdi-file-document-outline</v-icon>
                              <v-icon v-if="action.ActionType === 'Update'" @click="ActivateRelatedRecordForm(action)">mdi-file-document-outline</v-icon>
                              
                               </v-list-item-content>
                            
                              </v-list-item>
                              </v-list>
                              </v-list-item>
                            </v-list>
                    </v-card>
                </v-tab-item>
                <v-tab v-if="EditedAutomation.CallfromButton">Buttons and Dialog</v-tab>     
                  <v-tab-item v-if="EditedAutomation.CallfromButton">
                    <v-card class="padded">           
                        <v-card-title>
                          Button
                          </v-card-title>
                          
                          <v-card-text>
                            Here you can configure what the approve dialog will look like. Note there are two dialogs. Should any Conditions apply (any pre-requisites to being able to approve), then the second dialog (the "Cannot Approve" dialog) is what users will see when they try approve a record that does not meet the needed criteria
                          </v-card-text>
                             <v-list>
                               <v-list-item>
                                 <v-list-item-title>
                                   Button Configuration
                                 </v-list-item-title>
                                 </v-list-item>
                                
                               <v-list-item v-for="button in ConditionswithButtons" :key="button.itemObjKey">
                                 <v-list-item-content>
                                   <v-text-field v-model="button.Name"></v-text-field>
                                 </v-list-item-content>
                                   <v-btn small>
                                    {{button.ApprovalButtonText}}
                                  </v-btn>
                                 <v-list-item-content>
                                   <v-text-field v-model="button.ApprovalButtonText"></v-text-field>
                                 </v-list-item-content>
                               </v-list-item>
                             </v-list>
                           
                          </v-card>
                        <v-divider></v-divider>
                        <v-card class="padded">           
                        <v-card-title>
                          Dialog
                          </v-card-title>
                          
                          <v-card-text>
                            Here you can configure what the approve dialog will look like. Note there are two dialogs. Should any Conditions apply (any pre-requisites to being able to approve), then the second dialog (the "Cannot Approve" dialog) is what users will see when they try approve a record that does not meet the needed criteria
                          </v-card-text>
                          <v-card-subtitle>
                        Conditions Met
                      </v-card-subtitle>
                            <v-card class="mx-3 my-3" outlined :color="SystemThemes[0].Colors[2].Pick.hex">
                      
                      <v-card-title :color="SystemThemes[0].Colors[3].Pick.hex">
                        {{EditedAutomation.ApprovalButtonText}} {{tempName}}
                        </v-card-title>
                        <!-- <v-btn>Add Field</v-btn> -->
                        <v-card-text>
                        <div @click="EditedAutomation.ApprovalDialogTextEditing = !EditedAutomation.ApprovalDialogTextEditing" v-if="!EditedAutomation.ApprovalDialogTextEditing">{{EditedAutomation.ApprovalDialogText}}</div>
                        <v-textarea v-model="EditedAutomation.ApprovalDialogText" v-if="EditedAutomation.ApprovalDialogTextEditing"></v-textarea><v-btn v-if="EditedAutomation.ApprovalDialogTextEditing" @click="EditedAutomation.ApprovalDialogTextEditing = !EditedAutomation.ApprovalDialogTextEditing">close</v-btn>
                        </v-card-text>
                        <v-card-actions>
                          <v-layout class="justify-end">
                          <v-btn @click="EditedAutomation.ApprovalButtonTextEditing = !EditedAutomation.ApprovalButtonTextEditing" v-if="!EditedAutomation.ApprovalButtonTextEditing" :color="SystemThemes[0].Colors[0].Pick.hex" class="white--text">{{EditedAutomation.ApprovalButtonText}} <v-icon color="white">mdi-fast-forward</v-icon></v-btn>
                          <v-text-field v-model="EditedAutomation.ApprovalButtonText" v-if="EditedAutomation.ApprovalButtonTextEditing"></v-text-field><v-btn v-if="EditedAutomation.ApprovalButtonTextEditing" @click="EditedAutomation.ApprovalButtonTextEditing = !EditedAutomation.ApprovalButtonTextEditing">close</v-btn>
                          </v-layout>
                          </v-card-actions>
                      </v-card>
                      <v-card-subtitle>
                        Conditions NOT Met
                      </v-card-subtitle>
                      <v-card class="mx-3 my-3" outlined :color="SystemThemes[0].Colors[2].Pick.hex">
                   
                      <v-card-title :color="SystemThemes[0].Colors[3].Pick.hex">
                        Cannot {{EditedAutomation.ApprovalButtonText}} {{tempName}}
                        </v-card-title>
                        <!-- <v-btn>Add Field</v-btn> -->
                        <v-card-text>
                        <div @click="EditedAutomation.CannotApproveDialogTextEditing = !EditedAutomation.CannotApproveDialogTextEditing" v-if="!EditedAutomation.CannotApproveDialogTextEditing">{{EditedAutomation.CannotApproveDialogText}}</div>
                        <v-textarea v-model="EditedAutomation.CannotApproveDialogText" v-if="EditedAutomation.CannotApproveDialogTextEditing"></v-textarea><v-btn v-if="EditedAutomation.CannotApproveDialogTextEditing" @click="EditedAutomation.CannotApproveDialogTextEditing = !EditedAutomation.CannotApproveDialogTextEditing">close</v-btn>
                        <v-list>
                          <v-list-item>
                            <v-list-item-content>
                             <strong>Condition</strong>
                              </v-list-item-content>                             
                              <v-list-item-content>
                                <v-layout class="justify-end">
                               <strong>Status</strong>
                               </v-layout>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        <!-- <v-list>
                          <v-list-item v-for="condition in ProcessApprovalConditions" :key="condition.itemObjKey">
                            <v-list-item-content>
                              {{condition.Field.Name}} {{condition.Parameter}} <span v-if="condition.Parameter !== 'contains-data'">{{condition.Condition}}</span>
                              </v-list-item-content>                             
                              <v-list-item-content>
                                <v-layout class="justify-end">
                                <v-icon :color="SystemThemes[0].Colors[3].Pick.hex">mdi-cancel</v-icon>
                                </v-layout>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list> -->
                        </v-card-text>
                      
                      </v-card>
                          </v-card>
                  </v-tab-item>
            </v-tabs>
             </v-img>
         </v-card>
    </v-dialog>
    <!-- {{CreateAutomations[0].FormFields.length}} -->
    <v-toolbar dense src='@/assets/RapidappsParallax.jpeg'>
                            <v-toolbar-title><span class="white--text"><strong>{{CurrentEntity.DisplayName}} </strong></span><span class="font-weight-light red--text"> - Automations </span></v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-icon color="green" @click="saveBuild()">mdi-content-save</v-icon>
                  </v-toolbar>

    <v-tabs background-color="accent" dark centered>
      <v-tab>Developing</v-tab>
      <v-tab-item>
          <v-card class="padded accent" tile>
           <v-btn @click="AddBuildAutomation()" color="#eb371b" dark>Add Automation</v-btn>
        <v-list dense class="transparent" dark>
            <v-list-item class="baseoutline" v-for="auto in BuildAutomations" :key="auto.itemObjKey">
            <v-list-item-content>
                <strong>ID</strong> {{auto.ID}} - {{auto.Name}}
                </v-list-item-content>
                <v-list-item-action>
                <v-layout class="justify-end" @click="EditAutomation(auto)"><v-icon class="spin">mdi-cogs</v-icon></v-layout>
                </v-list-item-action>
            </v-list-item>
        </v-list>            
          </v-card>
      </v-tab-item>
      <v-tab>Buttons and Dialog</v-tab>
     
      <v-tab-item>
         <v-card class="padded accent" dark flat tile>           
            <v-card-title>
              Buttons
              </v-card-title>
              
              <v-card-text>
                          Here you can configure what the approve dialog will look like. Note there are two dialogs. Should any Conditions apply (any pre-requisites to being able to approve), then the second dialog (the "Cannot Approve" dialog) is what users will see when they try approve a record that does not meet the needed criteria
                          </v-card-text>
                
              </v-card>
            <v-divider></v-divider>
      </v-tab-item>

    </v-tabs>
        
</v-card>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'

export default {
    props: ['System','SystemEntities'],
    components: {},
    data() {
        return {
            FieldFilter: {Output: '', Input:[], Name: {}, InputType: 'Parent Field', ParentInput: ''},
            CurrentEntity: '',
            BuildAutomations: [],
            AutomationDialog: false,
            EditedAutomation: {},
            AutomationActionLastInteger: 1,
            RelatedRecordFormDialog: false,
            RelatedComponentTabs: [],
            RelatedComponentName: '',
            RelatedComponentSingle: '',
            RelatedRecord: '',
            EditedAutomationAction: {},
            EditedAutomationCondition: {},
            AutoFieldeditItem: '',
            AutomationFielddialog: false,
            TempParentInput: '',
            TempParentInputSource: '',
            LastAutomationInteger: 1000000,
            CalculatedNumberFieldMapDialog: false,
            CalculatedDateFieldMapDialog: false,
            CalculatedDateConfiguration: [
                {Name: 'Year Calculation', Boolean: 'HasYearConfig', BooleanLabel: 'Has Year Configuration', ObjectName: 'YearConfig' },
                {Name: 'Month Calculation', Boolean: 'MonthYearConfig',BooleanLabel: 'Has Month Configuration', ObjectName: 'MonthConfig' },
                {Name: 'Day Calculation', Boolean: 'HasDayConfig',BooleanLabel: 'Has Day Configuration', ObjectName: 'DayConfig' },
            ],
        }
    },
    computed:{
         RelatedRecordState(){
      if(this.RelatedRecord){
        let statusobj = this.RelatedRecord.AllFields.find(obj => obj.Name === 'Status')
        if(statusobj){
          return statusobj
        }
      }
    },
    ConditionswithButtons(){
      let NewArray = Array.from(this.BuildAutomations)
      return NewArray.map(auto => {
        return auto.Conditions
      }).flat().filter(cond => {
        return cond.CallfromButton
      })
    },
    FieldFilterTextTypeFields(){
      if(this.FieldFilter && this.FieldFilter.source && this.FieldFilter.source.AllFields){
      return this.FieldFilter.source.AllFields.filter(field => {
        return field.Type === 'Single Line Text' || field.Type === 'Multiple Lines Text' || field.Type === 'Date'
      })
      }
    },
    FieldFilterRelevantFields(){
        console.log('this.FieldFilter',this.FieldFilter)
      if(this.FieldFilter && this.FieldFilter.source && this.FieldFilter.source.AllFields){
       if(this.FieldFilter.Type === 'Single Line Text'){
      return this.FieldFilter.source.AllFields.filter(field => {
        return field.Type === 'Single Line Text'
      })
       }
       else if(this.FieldFilter.Type === 'Radio Group'){
      return this.FieldFilter.source.AllFields.filter(field => {
        return field.Type === 'Radio Group'
      })
       }
       else if(this.FieldFilter.Type === 'Common Field'){
      return this.FieldFilter.source.AllFields.filter(field => {
        return field.Type === this.FieldFilter.Type && field.CommonFieldType === this.FieldFilter.CommonFieldType
      })
       }
       else if(this.FieldFilter.Type === 'Multiple Lines Text'){
      return this.FieldFilter.source.AllFields.filter(field => {
        return field.Type === 'Multiple Lines Text'
      })
       }
       else if(this.FieldFilter.Type === 'Number Field'){
          return this.FieldFilter.source.AllFields.filter(field => {
            return field.Type === 'Number Field'
            })
          }
          else if(this.FieldFilter.Type === 'Date'){
          return this.FieldFilter.source.AllFields.filter(field => {
            return field.Type === 'Date'
            })
          }
          else if(this.FieldFilter.Type === 'Option Set'){
          return this.FieldFilter.source.AllFields.filter(field => {
            return field.Type === 'Option Set' && JSON.stringify(field.Options) === JSON.stringify(this.FieldFilter.Options) 
            })
          }
          else if(this.FieldFilter.Type === 'Global Option Set'){
          return this.FieldFilter.source.AllFields.filter(field => {
            return field.Type === 'Global Option Set' && JSON.stringify(field.Options) === JSON.stringify(this.FieldFilter.Options) 
            })
          }
          else if(this.FieldFilter.Type === 'Boolean'){
          return this.FieldFilter.source.AllFields.filter(field => {
            return field.Type === 'Boolean'
            })
          }
       else if(this.FieldFilter.Type === 'Lookup'){
      return this.FieldFilter.source.AllFields.filter(field => {
        if(field.Primary){
        console.log('buildfield')
        console.log(field)
        console.log('this.FieldFilter.source')
        console.log(this.FieldFilter.source)
        }
        return field.Type === 'Lookup' && field.RelatedBuildID === this.FieldFilter.RelatedBuildID || this.FieldFilter.source.id === this.FieldFilter.RelatedBuildID && field.Primary
      })
       }
       else if(this.FieldFilter.Type === 'Parent Field'){
         if(this.FieldFilter.ParentFieldObj.Type === 'Lookup'){
           return this.FieldFilter.source.AllFields.filter(field => {
              return field.Type === 'Lookup' && field.RelatedBuildID === this.FieldFilter.ParentFieldObj.RelatedBuildID || field.ParentFieldObj && field.ParentFieldObj.Type === 'Lookup' && field.ParentFieldObj.RelatedBuildID === this.FieldFilter.ParentFieldObj.RelatedBuildID
      })
         }
         else if(this.FieldFilter.ParentFieldObj.ParentFieldObj && this.FieldFilter.ParentFieldObj.ParentFieldObj.Type === 'Lookup'){
           return this.FieldFilter.source.AllFields.filter(field => {
              return field.ParentFieldObj && field.ParentFieldObj.Type === 'Lookup' && field.ParentFieldObj.RelatedBuildID === this.FieldFilter.ParentFieldObj.ParentFieldObj.RelatedBuildID || field.ParentFieldObj && field.ParentFieldObj.ParentFieldObj && field.ParentFieldObj.ParentFieldObj.Type === 'Lookup' && field.ParentFieldObj.ParentFieldObj.RelatedBuildID === this.FieldFilter.ParentFieldObj.ParentFieldObj.RelatedBuildID
          })
         }
         else if(this.FieldFilter.ParentFieldObj.Type === 'Single Line Text'){
          return this.FieldFilter.source.AllFields.filter(field => {
            return field.Type === 'Single Line Text'
            })
          }
          else if(this.FieldFilter.ParentFieldObj.Type === 'Number Field'){
          return this.FieldFilter.source.AllFields.filter(field => {
            return field.Type === 'Number Field'
            })
          }
          else if(this.FieldFilter.ParentFieldObj.Type === 'Date'){
          return this.FieldFilter.source.AllFields.filter(field => {
            return field.Type === 'Date'
            })
          }
          else if(this.FieldFilter.ParentFieldObj.Type === 'Boolean'){
          return this.FieldFilter.source.AllFields.filter(field => {
            return field.Type === 'Boolean'
            })
          }
       }
      }
    },
    FieldInputOptions(){
      if(this.FieldFilter.Type === 'Single Line Text'){
        return ['Combined Value','Parent Field']
      }
      if(this.FieldFilter.Type === 'Common Field'){
        return ['Parent Field']
      }
      if(this.FieldFilter.Type === 'Multiple Lines Text'){
        return ['Combined Value','Parent Field']
      }
      if(this.FieldFilter.Type === 'Option Set'){
        return ['Parent Field','Custom Value']
      }
      if(this.FieldFilter.Type === 'Radio Group'){
        return ['Parent Field','Custom Value']
      }
      if(this.FieldFilter.Type === 'Global Option Set'){
        return ['Parent Field','Custom Value']
      }
      if(this.FieldFilter.Type === 'Number Field' || this.FieldFilter.Type === 'Date'){
        return ['Parent Field','Calculated Value']
      }
      if(this.FieldFilter.Type === 'Lookup' || this.FieldFilter.Type === 'Parent Field'){
        return ['Parent Field']
      }
      if(this.FieldFilter.Type === 'Boolean'){
        return ['Parent Field','Custom Value']
      }
    },
    AllCurrentBuildRelatedBuilds(){
      let NewLookupBuilds = Array.from(this.LookupBuilds)
      let NewParentBuilds = Array.from(this.ParentBuilds)
      console.log('this.ParentBuilds')
          console.log(this.ParentBuilds)
      let arr1 = NewLookupBuilds.map(build => {
        build.Main = build.DisplayName+' ('+build.SourceField+')'
        build.Relationship = 'Lookup Field Build'
        build.RelationshipField = build.SourceField
        return build
      })
      let CurrentBuildObj = this.CurrentEntity
      CurrentBuildObj.Main = CurrentBuildObj.DisplayName
      CurrentBuildObj.Relationship = 'Build Self'
      let arr2 = [CurrentBuildObj]
      let arr3 = NewParentBuilds.map(build => {
        build.Main = build.DisplayName+' ('+build.SourceField+')'
        build.Relationship = 'Parent Field Build'
        build.RelationshipField = build.SourceField
        return build
      })
      console.log('arr1')
      console.log(arr1)
      console.log('arr3')
      console.log(arr3)
      let arr4 = this.CreateActionBuilds
      return arr1.concat(arr2,arr3,arr4)
    },
    BuildsArrayNonCurrent(){
      return this.SystemEntities.filter(build => {
        return build
      })
    },
      CreateAutomations(){
        let NewBuildsArray = JSON.prase(JSON.stringify(this.SystemEntities))
        let NewAutomations = JSON.prase(JSON.stringify(this.BuildAutomations))
        return NewAutomations.map(auto => {
          return auto.AutomationActions
        }).flat().filter(action => {
          return action.ActionType === 'Create'
        }).map(method => {
        console.log(method)
        if(method.Record && method.Record.id && method.FieldMapping){
          let filterfieldmap = method.FieldMapping.filter(mapping => {
            mapping.targetfield = mapping.Name
            mapping.target = {temppluralname: method.Record.temppluralname, DisplayName: method.Record.DisplayName,id: method.Record.id}
            if(mapping.InputType === 'Parent Field'){
              mapping.sourcefield = mapping.Input[0]
            }
            console.log('mapping during attempt')
            console.log(mapping)
            return mapping.targetfield.Name && mapping.source && mapping.source.id
          })
        let fieldmap = filterfieldmap.map(actionmap => {
          console.log('unsure unchecked actionmap')
          console.log(actionmap)
          if(actionmap.targetfield.Name && actionmap.source.id){
            console.log('has source and target field')
            console.log(actionmap)

          actionmap.targetfield.SourceObj = {Source: {DisplayName: actionmap.source,DisplayName: actionmap.source.temppluralname,id: actionmap.source.id},SourceField: actionmap.sourcefield}
          delete actionmap.targetfield.SourceObj.AllFields
          return actionmap.targetfield
          }
          else{
            console.log('no source or target field')
            console.log(actionmap)
          }
          
        })
        let methodobj = {MethodID: method.MethodID, MethodName: method.MethodName, DisplayName: method.Record.DisplayName,temppluralname: method.Record.temppluralname, RecordID: method.Record.id, ActionType: method.ActionType, MapFields: fieldmap}
        let buildobj = NewBuildsArray.find(obj => obj.id == methodobj.RecordID)
        if(buildobj && buildobj.AllFields){
          methodobj.AllCollectionFields = buildobj.AllFields.filter(field => {
          // console.log('formsfield field')
          // console.log(field)          
          return field.IsHeader && !methodobj.MapFields.find(obj => obj.Name == field.Name)          
        })
        }
        
        
        return methodobj
        }
        else{
          return method
          }
        
        }).map(methodobj => {
          console.log(methodobj)
          if(methodobj.RecordID && methodobj.MapFields){
        let AllFieldsPrimaryMandatoryCheck = methodobj.MapFields.map(field => {
          let buildobj = NewBuildsArray.find(obj => obj.id == methodobj.RecordID)
          let buildfield = buildobj.AllFields.find(obj => obj.Name === field.Name)
          if(buildfield.Primary){
            field.Primary = buildfield.Primary
          }
          if(buildfield.IsMandatory){
            field.IsMandatory = buildfield.IsMandatory
          }
          if(buildfield.SystemRequired){
            field.SystemRequired = buildfield.SystemRequired
          }
        })
        methodobj.FormFields = methodobj.MapFields.concat(methodobj.AllCollectionFields)
        return methodobj
        }
      })
      },
      RelatedFields(){
        return this.AllRelatedFields.filter(field => {
          return field.Type === 'Single Line Text' || field.Type === 'Date' || field.Type === 'Number'
        })
      },
      AllRelatedFields(){
          let arr1 = this.AllFields.map(field => {
              field.sourcename = field.DisplayName
              return field
          })
          let arr4 = this.LookupBuilds.map(build => {
              let AllLookupBuildFields = build.AllFields.map(field => {
                  field.sourcename = field.DisplayName+' ('+build.SourceField+')'
                  field.SourceField = build.SourceField
                  console.log('field')
                  console.log(field)
                  return field
              })
             
              return AllLookupBuildFields
          }).flat()
          console.log('this.LookupBuildsLookups')
          console.log(this.LookupBuildsLookups)
          let arr5 = this.LookupBuildsLookups.map(build => {
              let AllLookupBuildLookups = build.AllFields.map(field => {
                  field.sourcename = field.DisplayName+' ('+build.Parentsourcename+' - '+build.ParentSourceField+')'
                  field.SourceField = build.SourceField
                  field.ParentSourceField = build.ParentSourceField
                  field.Parentsourcename = build.Parentsourcename
                  return field
              })
              
              return AllLookupBuildLookups
          }).flat()
          console.log('this.ParentBuildsLookups')
          console.log(this.ParentBuildsLookups)
          let arr6 = this.ParentBuildsLookups.map(build => {
              let AllParentBuildFields = build.AllFields.map(field => {
                  field.sourcename = field.DisplayName+' ('+build.Parentsourcename+' - '+build.ParentSourceField+')'
                  field.SourceField = build.SourceField
                  field.ParentSourceField = build.ParentSourceField
                  field.Parentsourcename = build.Parentsourcename
                  return field
              })

              
              return AllParentBuildFields
          }).flat()
          console.log('arr6')
          console.log(arr6)
            console.log('arr5')
          console.log(arr5)
          console.log('arr4')
          console.log(arr4)
          let arr3 = arr1.concat(arr4,arr5,arr6)
          return arr3
      },
      OnlyDateFields(){
        let arr1 = this.DateFields.map(field => {
              field.sourcename = field.DisplayName
              return field
          })
         
          let arr4 = this.LookupBuilds.map(build => {
              let builddatefields = build.datefields.map(field => {
                  field.sourcename = field.DisplayName+' ('+build.SourceField+')'
                  field.SourceField = build.SourceField
                  console.log('field')
                  console.log(field)
                  return field
              })
            
             
              return builddatefields
          }).flat()
          console.log('this.LookupBuildsLookups')
          console.log(this.LookupBuildsLookups)
          let arr5 = this.LookupBuildsLookups.map(build => {
              let builddatefields = build.datefields.map(field => {
                  field.sourcename = field.DisplayName+' ('+build.Parentsourcename+' - '+build.ParentSourceField+')'
                  field.SourceField = build.SourceField
                  field.ParentSourceField = build.ParentSourceField
                  field.Parentsourcename = build.Parentsourcename
                  return field
              })
              
              return builddatefields
          }).flat()
          console.log('this.ParentBuildsLookups')
          console.log(this.ParentBuildsLookups)
          let arr6 = this.ParentBuildsLookups.map(build => {
              let builddatefields = build.datefields.map(field => {
                  field.sourcename = field.DisplayName+' ('+build.Parentsourcename+' - '+build.ParentSourceField+')'
                  field.SourceField = build.SourceField
                  field.ParentSourceField = build.ParentSourceField
                  field.Parentsourcename = build.Parentsourcename
                  return field
              })
             
              return builddatefields
          }).flat()
          console.log('arr6')
          console.log(arr6)
            console.log('arr5')
          console.log(arr5)
          console.log('arr4')
          console.log(arr4)
          let arr3 = arr1.concat(arr4,arr5,arr6)
          return arr3
      },
      OnlyNumberFields(){
        let arr1 = this.NumberFields.map(field => {
              field.sourcename = field.DisplayName
              return field
          })
         
          let arr4 = this.LookupBuilds.map(build => {
              let buildnumberfields = build.numberfields.map(field => {
                  field.sourcename = field.DisplayName+' ('+build.SourceField+')'
                  field.SourceField = build.SourceField
                  console.log('field')
                  console.log(field)
                  return field
              })
            
             
              return buildnumberfields
          }).flat()
          console.log('this.LookupBuildsLookups')
          console.log(this.LookupBuildsLookups)
          let arr5 = this.LookupBuildsLookups.map(build => {
              let buildnumberfields = build.numberfields.map(field => {
                  field.sourcename = field.DisplayName+' ('+build.Parentsourcename+' - '+build.ParentSourceField+')'
                  field.SourceField = build.SourceField
                  field.ParentSourceField = build.ParentSourceField
                  field.Parentsourcename = build.Parentsourcename
                  return field
              })
              
              return buildnumberfields
          }).flat()
          console.log('this.ParentBuildsLookups')
          console.log(this.ParentBuildsLookups)
          let arr6 = this.ParentBuildsLookups.map(build => {
              let buildnumberfields = build.numberfields.map(field => {
                  field.sourcename = field.DisplayName+' ('+build.Parentsourcename+' - '+build.ParentSourceField+')'
                  field.SourceField = build.SourceField
                  field.ParentSourceField = build.ParentSourceField
                  field.Parentsourcename = build.Parentsourcename
                  return field
              })
             
              return buildnumberfields
          }).flat()
          console.log('arr6')
          console.log(arr6)
            console.log('arr5')
          console.log(arr5)
          console.log('arr4')
          console.log(arr4)
          let arr3 = arr1.concat(arr4,arr5,arr6)
          return arr3
      },
      DatesandNumberFields(){
          let arr1 = this.NumberFields.map(field => {
              field.sourcename = field.DisplayName
              return field
          })
          let arr2 = this.DateFields.map(field => {
              field.sourcename = field.DisplayName
              return field
          })
          let arr4 = this.LookupBuilds.map(build => {
              let buildnumberfields = build.numberfields.map(field => {
                  field.sourcename = field.DisplayName+' ('+build.SourceField+')'
                  field.SourceField = build.SourceField
                  console.log('field')
                  console.log(field)
                  return field
              })
             
              return buildnumberfields
          }).flat()
          console.log('this.LookupBuildsLookups')
          console.log(this.LookupBuildsLookups)
          let arr5 = this.LookupBuildsLookups.map(build => {
              let buildnumberfields = build.numberfields.map(field => {
                  field.sourcename = field.DisplayName+' ('+build.Parentsourcename+' - '+build.ParentSourceField+')'
                  field.SourceField = build.SourceField
                  field.ParentSourceField = build.ParentSourceField
                  field.Parentsourcename = build.Parentsourcename
                  return field
              })
              
              return buildnumberfields
          }).flat()
          console.log('this.ParentBuildsLookups')
          console.log(this.ParentBuildsLookups)
          let arr6 = this.ParentBuildsLookups.map(build => {
              let buildnumberfields = build.numberfields.map(field => {
                  field.sourcename = field.DisplayName+' ('+build.Parentsourcename+' - '+build.ParentSourceField+')'
                  field.SourceField = build.SourceField
                  field.ParentSourceField = build.ParentSourceField
                  field.Parentsourcename = build.Parentsourcename
                  return field
              })
             
              return buildnumberfields
          }).flat()
          console.log('arr6')
          console.log(arr6)
            console.log('arr5')
          console.log(arr5)
          console.log('arr4')
          console.log(arr4)
          let arr3 = arr1.concat(arr2,arr4,arr5,arr6)
          return arr3
      },
      
      LookupBuilds() {
      return this.SystemEntities.filter(build => {
          let newbuild = Object.assign({},build)
        let lookupobj = this.LookupFields.find(obj => obj.RelatedBuildID == build.id)
        if(lookupobj){
          newbuild.SourceField = lookupobj.Name
          newbuild.sourcename = newbuild.DisplayName+' ('+newbuild.SourceField+')'
          return newbuild
        }
      })
      },
      ParentBuilds() {
      return this.SystemEntities.filter(build => {
          let newbuild = Object.assign({},build)
        let lookupobj = this.ParentFields.find(obj => obj.ParentFieldObj.RelatedBuildID == build.id)
        if(lookupobj){
          newbuild.RelatedRecord = lookupobj.RelatedRecord
          newbuild.SourceField = lookupobj.Name
          newbuild.sourcename = newbuild.DisplayName+' ('+newbuild.SourceField+')'
          return newbuild
        }
      })
      },
      CreateActionBuilds() {
        if(this.EditedAutomation && this.EditedAutomation.AutomationActions){
        let NewActions = this.EditedAutomation.AutomationActions
        let NewCreateActions = NewActions.filter(action => {
            return action.ActionType === 'Create'
          })
          console.log('this.AutomationActions')
          console.log(NewCreateActions)
          return NewCreateActions.map(action => {
        // let lookupobj = this.LookupFields.find(obj => obj.RelatedBuildID == build.id)
        // if(lookupobj){
          let NewBuildsArray = Array.from(this.SystemEntities)
          
          let buildobj = NewBuildsArray.find(obj => obj.id === action.Record.id)
          if(buildobj){
          let actionbuild = {temppluralname: buildobj.temppluralname,DisplayName: buildobj.DisplayName,id: buildobj.id, AllFields: buildobj.AllFields}
          
          actionbuild.SourceField = action.MethodID
          actionbuild.sourcename = action.Record.DisplayName+' ('+action.MethodName+')'
          actionbuild.Main = actionbuild.sourcename
          actionbuild.Relationship = 'Create Method Build'
          actionbuild.RelationshipField = actionbuild.SourceField
          actionbuild.source = action.Record
          console.log('actionbuild')
          console.log(actionbuild)
          // action.source = action.Record
          // action.AllFields = action.source.AllFields
          
          return actionbuild
          }
        // }
      })
        }
      },
      ParentBuildsLookups() {
          let LookupBuildsArray = Array.from(this.ParentBuilds)
          
      return LookupBuildsArray.map(build => {
        let newarray = this.SystemEntities.filter(lookupbuild => {
            let newbuild = Object.assign({},lookupbuild)
                let lookupobj = build.AllFields.find(obj => obj.RelatedBuildID == lookupbuild.id)
                if(lookupobj){
                    newbuild.SourceField = build.SourceField
                    newbuild.ParentSourceField = lookupobj.Name
                    newbuild.Parentsourcename = build.DisplayName
                    return newbuild
                }
        })
        return newarray       
      }).flat()
      },
      ParentLookupBuilds() {
      return this.SystemEntities.filter(build => {
          let newbuild = Object.assign({},build)
        let lookupobj = this.ParentFields.find(obj => obj.ParentFieldObj.RelatedBuildID == build.id)
        if(lookupobj){
          newbuild.SourceField = lookupobj.Name
          newbuild.sourcename = newbuild.DisplayName+' ('+newbuild.SourceField+')'
          return newbuild
        }
      })
      },
      LookupBuildsLookups() {
          let LookupBuildsArray = Array.from(this.LookupBuilds)
      return LookupBuildsArray.map(build => {
        let newarray = this.SystemEntities.filter(lookupbuild => {
            let newbuild = Object.assign({},lookupbuild)
            let lookupobj = build.lookupfields.find(obj => obj.RelatedBuildID == lookupbuild.id)
            if(lookupobj){
                newbuild.SourceField = build.SourceField
                newbuild.ParentSourceField = lookupobj.Name
                newbuild.Parentsourcename = build.DisplayName
                return newbuild
            }
        })
        return newarray       
      }).flat()
      },
      
      LookupBuildsParents() {
          let LookupBuildsArray = Array.from(this.LookupBuildsParents)
      return LookupBuildsArray.map(build => {
        let newarray = this.SystemEntities.filter(lookupbuild => {
        let newbuild = Object.assign({},lookupbuild)
        let lookupobj = build.lookupfields.find(obj => obj.RelatedBuildID == lookupbuild.id)
        if(lookupobj){
            newbuild.SourceField = build.SourceField
            newbuild.ParentSourceField = lookupobj.Name
            newbuild.Parentsourcename = build.DisplayName
          return newbuild
        }
        })
        return newarray       
      }).flat()
      },
      RelatedNumberFields(){
          let relbuildobj = this.SystemEntities.find(obj => obj.id === this.RelatedRecord.id)
          return relbuildobj.AllFields.filter(yyfield => {return field.Type == 'Number Field'})
      },
      FieldsforAutomation(){
      if(typeof this.FieldFilter !== 'undefined'){
      // if(this.FieldFilter.Type === 'Single Line Text'){
      //   return this.AllFields
      // }
      // else {
      // return this.AllFields.filter(field => {
      //  return field.Type === this.FieldFilter.Type && field.Type !== 'Option Set'
      // })
      // }
        return this.FieldFilter.Input
      }
    },
      AllFields() {
      return this.CurrentEntity.AllFields
    },
    LookupFields(){
          return this.CurrentEntity.AllFields.filter(field => {
              return field.Type === 'Lookup'
          })
      },
    DateFields(){
          return this.CurrentEntity.AllFields.filter(field => {
              return field.Type === 'Date'
          })
      },
    NumberFields(){
          return this.CurrentEntity.AllFields.filter(field => {
              return field.Type === 'Number Field'
          })
      },
    ParentFields(){
          return this.CurrentEntity.AllFields.filter(field => {
              return field.Type === 'Parent Field'
          })
      },
    AutomationConditionsShortened(){
        return this.EditedAutomation.Conditions.map(condition => {          
        
        if(condition.Steps && condition.Steps.length >0){
          let NewSteps = condition.Steps.map(step => {
            let SimplestepRecord = {temppluralname: step.Record.temppluralname,DisplayName: step.Record.DisplayName,id: step.Record.id, AllFields: step.Record.AllFields}
        if(step.Record.Main){
        SimplestepRecord.Main = step.Record.Main
        }
        if(step.Record.RelationshipField){
          SimplestepRecord.RelationshipField = step.Record.RelationshipField
        }
        if(step.Record.Relationship){
          SimplestepRecord.Relationship = step.Record.Relationship
        }
        if(step.Record.RelatedRecord){
          SimplestepRecord.RelatedRecord = step.Record.RelatedRecord
        }
        step.Record = SimplestepRecord
        return step
          })
        condition.Steps = NewSteps
        }
        return condition
        })
    },
    MappedFields(){
        return this.RelatedComponentTabs.map(tab => {
            return tab.Elements.map(section => {
                return section.Fields
            }).flat()
        }).flat().filter(field => {
            return field.Input
        })
    },
    ShortenedActionRecord(){
        let action = this.EditedAutomationAction
        // if(action.Record.id === this.CurrentEntity.id){
        //  let SimpleRecord = {temppluralname: action.Record.temppluralname,DisplayName: action.Record.DisplayName,id: action.Record.id, AllFields: action.Record.AllFields, SingleBuildTabs: action.Record.SingleBuildTabs}
        // return SimpleRecord
        // }
        // else{
          
        let SimpleRecord = {temppluralname: action.Record.temppluralname,DisplayName: action.Record.DisplayName,id: action.Record.id, AllFields: action.Record.AllFields, SingleBuildTabs: this.RelatedComponentTabs}
        if(action.Record.Main){
          SimpleRecord.Main = action.Record.Main
        }
        if(action.Record.RelationshipField){
          SimpleRecord.RelationshipField = action.Record.RelationshipField
        }
        if(action.Record.Relationship){
          SimpleRecord.Relationship = action.Record.Relationship
        }
        if(action.Record.RelatedRecord){
          SimpleRecord.RelatedRecord = action.Record.RelatedRecord
        }
        return SimpleRecord
        // }
        
    },
    BuildAutomationsShortened(){
      let NewArray = Array.from(this.BuildAutomations)
      let AutoArray = NewArray.map(auto => {
        if(auto.AutomationActions.length >0){
        let Actions = auto.AutomationActions.map(action => {
          action.Record.AllFields.map(field => {
            delete field.Headers
            if(field.ParentFieldObj){
              delete field.ParentFieldObj.Headers
            }
          })
          // delete action.Record.SingleBuildTabs
          console.log(action.Record)
          if(action.FieldMapping){
            action.FieldMapping.map(fieldmap => {
              let fieldmapobj = Object.assign({},fieldmap)
              console.log('fieldmapobj')
              console.log(fieldmapobj)
            if(fieldmap.source){
              if(fieldmap.source.Main && fieldmap.source.Relationship && fieldmap.source.RelationshipField){
                fieldmap.source = {Relationship: fieldmap.source.Relationship, RelationshipField: fieldmap.source.RelationshipField, id: fieldmap.source.id, DisplayName: fieldmap.source.DisplayName, temppluralname: fieldmap.source.temppluralname, Main: fieldmap.source.Main}
              }
              else if(fieldmap.source.Main && fieldmap.source.Relationship && !fieldmap.source.RelationshipField){
                 fieldmap.source = {Relationship: fieldmap.source.Relationship, id: fieldmap.source.id, DisplayName: fieldmap.source.DisplayName, temppluralname: fieldmap.source.temppluralname, Main: fieldmap.source.Main}
              }
              else if(fieldmap.source.Main && !fieldmap.source.Relationship && !fieldmap.source.RelationshipField){
                 fieldmap.source = {id: fieldmap.source.id, DisplayName: fieldmap.source.DisplayName, temppluralname: fieldmap.source.temppluralname, Main: fieldmap.source.Main}
              }
              else if(!fieldmap.source.Main && fieldmap.source.Relationship && fieldmap.source.RelationshipField){
                 fieldmap.source = {Relationship: fieldmap.source.Relationship, RelationshipField: fieldmap.source.RelationshipField, id: fieldmap.source.id, DisplayName: fieldmap.source.DisplayName, temppluralname: fieldmap.source.temppluralname}
              }
              else if(fieldmap.source.Main && !fieldmap.source.Relationship && fieldmap.source.RelationshipField){
                 fieldmap.source = {RelationshipField: fieldmap.source.RelationshipField, id: fieldmap.source.id, DisplayName: fieldmap.source.DisplayName, temppluralname: fieldmap.source.temppluralname, Main: fieldmap.source.Main}
              }
              else if(!fieldmap.source.Main && fieldmap.source.Relationship && !fieldmap.source.RelationshipField){
                 fieldmap.source = {Relationship: fieldmap.source.Relationship, id: fieldmap.source.id, DisplayName: fieldmap.source.DisplayName, temppluralname: fieldmap.source.temppluralname}
              }
              else{
                fieldmap.source = {id: fieldmap.source.id, DisplayName: fieldmap.source.DisplayName, temppluralname: fieldmap.source.temppluralname}
              }
              if(fieldmapobj.source.RelatedRecord){
                fieldmap.source.RelatedRecord = fieldmapobj.source.RelatedRecord
              }
              
            }
            console.log('fieldmap')
            console.log(fieldmap)
            return fieldmap
          })

          //   action.FieldMapping.map(fieldmap => {
          //     let fieldmapobj = Object.assign({},fieldmap)
          //   if(fieldmap.source){
              
          //       fieldmap.source = {id: fieldmap.source.id, DisplayName: fieldmap.source.DisplayName, temppluralname: fieldmap.source.temppluralname}
          //     if(fieldmap.source.Main){
          //       fieldmap.source.Main = fieldmapobj.source.Main
          //     }
              
          //   }
          //   return fieldmap
          // })


          // action.FieldMapping.map(fieldmap => {
          //   let fieldmapobj = Object.assign({},fieldmap)  
          //   if(fieldmap.source){
          //           console.log('has source')
          //           console.log(fieldmap.source)     
            
          //       fieldmapobj.source = {id: fieldmap.source.id, DisplayName: fieldmap.source.DisplayName, temppluralname: fieldmap.source.temppluralname}
          //    if(fieldmap.source.Main){
          //       fieldmapobj.source.Main = fieldmap.source.Main
          //     }
          //     if(fieldmap.source.Relationship){
          //       fieldmapobj.source.Relationship = fieldmap.source.Relationship
          //     }
          //     if(fieldmap.source.RelationshipField){
          //       fieldmapobj.source.RelationshipField = fieldmap.source.RelationshipField
          //     }
              
          //   }
          //   console.log('fieldmapobj')
          //           console.log(fieldmapobj)
          //   return fieldmapobj
          // })


          
        }
          // let TempMain = action.Record.Main
          let actionRecord = { temppluralname: action.Record.temppluralname,DisplayName: action.Record.DisplayName,id: action.Record.id, AllFields: action.Record.AllFields, SingleBuildTabs: action.Record.SingleBuildTabs}
          if(action.Record.Main){
            actionRecord.Main = action.Record.Main
          }

          if(action.Record.Relationship){
          actionRecord.Relationship = action.Record.Relationship
          }
          if(action.Record.RelationshipField){
            actionRecord.RelationshipField = action.Record.RelationshipField
          }
          if(action.Record.RelatedRecord){
            actionRecord.RelatedRecord = action.Record.RelatedRecord
          }
          action.Record = actionRecord
          // if(typeof TempMain !== 'undefined'){
          //   action.Record.Main = TempMain
          // }
          console.log('action')
          console.log(action)
          return action
        })
        let Conditions = auto.Conditions.map(condition => {
          condition.Steps = condition.Steps.map(step => {
            step.Record.AllFields.map(field => {
              delete field.Headers
               if(field.ParentFieldObj){
              delete field.ParentFieldObj.Headers
            }
            })
            return step
          })
            let shortcondmethod = condition.ResultActions.map(resultact => {
            
            return {MethodID: resultact.MethodID, MethodName: resultact.MethodName}
          })
          condition.ResultActions = shortcondmethod
          let buttonobj = this.ConditionswithButtons.find(obj => obj.StepNumber === condition.StepNumber)
          if(buttonobj && buttonobj.ApprovalButtonText){
            condition.ApprovalButtonText = buttonobj.ApprovalButtonText
          }
          return condition
        })
        auto.Conditions = Conditions
        auto.AutomationActions = Actions
        }
        return auto
      
      })
      return AutoArray
    },
    ShortenedAutomationActions(){
      if(this.EditedAutomation && this.EditedAutomation.AutomationActions){
        console.log("this.EditedAutomation",this.EditedAutomation)
      return this.EditedAutomation.AutomationActions.map(auto => {
        return {MethodID: auto.MethodID, MethodName: auto.MethodName}
      })
      }
    },
        RouteID(){
            return this.$route.params.id
        },
        ConfigDB(){
            return db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).collection('entities').doc(this.$route.params.id).collection('automations')
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        userBU () {
        return this.$store.state.buobj
        },
        
    },
    created(){
        if(!this.userIsAdmin){
            //reroute
        }
        else{
            this.LastAutomationInteger = 1000000
            if(this.System.LastAutomationInteger){
                this.LastAutomationInteger = this.System.LastAutomationInteger
            }
            this.CurrentEntity = this.SystemEntities.find(obj => obj.id === this.$route.params.id)
            console.log('this.SystemEntities',this.SystemEntities)
            //this.EntityProcesses = JSON.parse(JSON.stringify(this.CurrentEntity.EntityProcesses))
            this.BuildAutomations = this.CurrentEntity.automations
           
        }
        
    },
    methods:{
        RemoveFieldInputs(){
      this.FieldFilter.Input = []
      if(this.FieldFilter.Type === 'Boolean'){
         this.AddAutomationFieldInput()
      }
    },
    CheckifCurrent(build){
      if(build.id === this.CurrentEntity.id){
        this.SourceisCurrent
      }
    },
    ToggleDateProperty(boolean, object){
      if(this.FieldFilter[boolean]){
        this.FieldFilter[object] = {}        
      }
      else{
        delete this.FieldFilter[object]
      }
      console.log('this.FieldFilter')
      console.log(this.FieldFilter)
    },
      CancelRelatedRecordFormDialog(){
          this.RelatedRecordFormDialog = false
      },
      SaveCalculatedNumberFieldMap(){
          this.SaveAutomationRelated()
          this.CancelCalculatedNumberFieldMapDialog()
      },
      SaveCalculatedDateFieldMap(){
          this.SaveAutomationRelated()
          this.CancelCalculatedDateFieldMapDialog()
      },
      CancelCalculatedNumberFieldMapDialog(){
          this.CalculatedNumberFieldMapDialog = false
      },
      CancelCalculatedDateFieldMapDialog(){
          this.CalculatedDateFieldMapDialog = false
      },
      AddCalcDateFieldFormula(object){
        if(!object.Integers){
          object.Integers = []
        }
        let NewFormula = {input: '',integer: 0,operation: '',hasoperation: true}
           object.Integers.push(NewFormula)
           this.ToggleCalculatedDateFieldMapDialog()
      },
      AddCalcFieldFormula(){
          if(!this.FieldFilter.FieldFormulas){
              this.FieldFilter.FieldFormulas = []
          }
          
           let NewFormula = {input: '',integer: 0,operation: '',hasoperation: true}
           this.FieldFilter.FieldFormulas.push(NewFormula)
      },
      CheckOperator(formulaoperation){
      if(formulaoperation === '='){

      }
      else{
        this.AddCalcFieldFormula()
      }
    },
    ToggleCalculatedNumberFieldMapDialog(){
        this.CalculatedNumberFieldMapDialog = false
        this.CalculatedNumberFieldMapDialog = true
    },
    ToggleCalculatedDateFieldMapDialog(){
        this.CalculatedDateFieldMapDialog = false
        this.CalculatedDateFieldMapDialog = true
    },
      ActivateCalculatedNumberFieldMapDialog(field,index){
          console.log('FieldFilter')
          console.log(this.FieldFilter)
      
          this.CalculatedNumberFieldMapDialog = true
      },
      ActivateCalculatedDateFieldMapDialog(field,index){
          console.log('FieldFilter')
          console.log(this.FieldFilter)
          
          this.CalculatedDateFieldMapDialog = true
      },
      AddAutomationFieldInput(){

      console.log('this fieldfilter')
      console.log(this.FieldFilter)
      let fieldobject = this.FieldFilter
      let FieldInput = {
        Name: 'Field',
        DisplayName: 'Field'

      }
      let arraytest = Array.isArray(this.FieldFilter.Input)
      if(arraytest === true){
          this.FieldFilter.Input.push(FieldInput)
          this.AssignFieldOutput()
        }
        else{
          this.FieldFilter.Input = []
          this.FieldFilter.MapType = 'Parent Field'
          this.FieldFilter.Input.push(FieldInput)
          this.AssignFieldOutput()
        }

    },
    AddConditionCheck(child,type){
       if(typeof child.Steps === 'undefined'){
        child.Steps = []
        let stepnumberstring = child.StepNumber+'.1'
        console.log(stepnumberstring)
        let stepnumber = Number(stepnumberstring)
        console.log(stepnumber)
        let NewStep = {
        Name: 'Name',
        StepNumber: stepnumberstring,
        Action: {FieldtoCheck: {}},
      }
      let parentindex = this.EditedAutomation.Conditions.indexOf(child)
     
      child.Steps.push(NewStep)
      }
      else{
        console.log(child)
        let stepsuffix = child.Steps.length-1+2
      let stepnumberstring = child.StepNumber+'.'+stepsuffix
      console.log(stepnumberstring)
      let stepnumber = Number(stepnumberstring)
      console.log(stepnumber)
      let NewStep = {
        Name: 'Name',
        StepNumber: stepnumberstring,
        Action: {FieldtoCheck: {}},
      }
      let parentindex = this.EditedAutomation.Conditions.indexOf(child)
     
      child.Steps.push(NewStep)
      }
      
      console.log(this.EditedAutomation.Conditions)
    },
      AssignApprovalActionMethodsCondition(step){
      this.EditedActionMethodCondition = {}
      console.log('assigned step')
      console.log(step)
      this.EditedActionMethodCondition = step
    },
      ActivateAutomationFieldDialog(field, fieldindex) {
      this.AutomationFielddialog = true
      this.AutoFieldeditItem = field
    },
      ActivateRelatedRecordForm(action){
        // if(action.Record.id !== this.CurrentEntity.id){
          this.RelatedRecord = action.Record
          this.EditedAutomationAction = action
          this.RelatedComponentSingle = this.RelatedRecord
          this.RelatedComponentName = this.RelatedRecord.Name
          let OldRelatedComponentTabs = action.Record.SingleBuildTabs
          let RelatedComponentTabFields = OldRelatedComponentTabs.map(tab => {
            return tab.Elements.map(section =>{
              return section.Fields
            }).flat()
          }).flat()
          console.log('RelatedComponentTabFields')
          console.log(RelatedComponentTabFields)
          let entityobj = this.SystemEntities.find(obj => obj.id === this.RelatedRecord.id)
          let relbuildobj = Object.assign({},entityobj)
          console.log('relbuildobj',relbuildobj)
          let relatedbuildtabs = relbuildobj.SingleBuildTabs
          this.RelatedComponentTabs = relatedbuildtabs.map(tab => {
            tab.Elements = tab.Elements.map(section => {
                if(section.Fields){
                   section.Fields = section.Fields.map(field => {
                let fieldrelationobj = RelatedComponentTabFields.find(obj => obj.Name === field.Name)
                let fieldreturnobj = {}
                if(typeof fieldrelationobj !== 'undefined'){
                  console.log('it is this')
                  console.log(fieldrelationobj)
                  fieldreturnobj = fieldrelationobj
                }
                else{
                  fieldreturnobj = field
                }
                // else{
                  return fieldreturnobj
                // }
              }) 
                }
              
              return section
            })

          return tab
          })
          // this.RelatedComponentTabs = relatedbuildtabs
          console.log('this.RelatedComponentTabs')
          console.log(this.RelatedComponentTabs)
        //   this.RelatedComponentTabs = relbuildobj.SingleBuildTabs
        // }
        // else{
        //   this.RelatedRecord = this.CurrentEntity
        //   this.EditedAutomationAction = action
        //   this.RelatedComponentSingle = this.CurrentEntity
        //   this.RelatedComponentName = this.CurrentEntity.Name
        //   this.RelatedComponentTabs = this.CurrentEntity.SingleBuildTabs
        // }
          this.RelatedRecordFormDialog = true
      },
      SaveAutomationRelated(){
          this.EditedAutomation = this.EditedAutomation
          let actionobj = this.EditedAutomation.AutomationActions.find(obj => obj.MethodID === this.EditedAutomationAction.MethodID)
        let actionindex = this.EditedAutomation.AutomationActions.indexOf(actionobj)
        this.EditedAutomation.AutomationActions[actionindex].FieldMapping = this.MappedFields
        this.EditedAutomation.AutomationActions[actionindex].Record = this.ShortenedActionRecord
        
          this.SaveAutomation()
      },
      saveBuild(){
        this.SaveAutomation()
      },
      SaveAutomation(){
        if(this.EditedAutomation.Conditions){
          this.EditedAutomation.Conditions = this.AutomationConditionsShortened
        }
          console.log(this.BuildAutomationsShortened)
        this.BuildAutomationsShortened.map(auto => {
          let autostring = auto.ID.toString()
          this.ConfigDB.doc(autostring).set(auto).then(doc => {
              console.log('updated '+autostring+' automation using this.BuildAutomationsShortened')
          })
        })
          
          this.$emit('ShowBuildSnackbar','Buildautomationssnackbar',4000,this.tempPluralName)
      },
      ActivateFieldDrawer(){
        this.fielddrawer = true
      },
      AssignFieldFilter(tab, tabindex, section, sectionindex, field, fieldindex) {
      console.log('here is the field you assign')
      console.log(field)
      this.FieldFilter = field
      

      if(typeof field.InputType !== 'undefined' && field.InputType === 'Parent Field'){
        if(this.FieldFilter.source.id === this.CurrentEntity.id){
          this.FieldFilter.source.AllFields = Array.from(this.AllFields)
        }
        else{
        let buildobj  = this.SystemEntities.find(obj => obj.id === this.FieldFilter.source.id)
      this.FieldFilter.source.AllFields = Array.from(buildobj.AllFields)
        }
        if(field.Input.length>0){
        this.TempParentInput = field.Input[0].ParentInput
        }
      }
      console.log('and lookey here the fieldfilter after assigning')
      console.log(this.FieldFilter)
      if(this.FieldFilter.Input){
        if(typeof this.FieldFilter.Input.length !== 'undefined'){

        }
      }
    //  console.log('here is the assigned field details')
    //  console.log('tab-')
    //  console.log(tabindex)
    //  console.log(tab)
    //  console.log('section-')
    //  console.log(sectionindex)
    //  console.log(section)
     console.log('field-')
     console.log(fieldindex)
     console.log(field)
     console.log('relatedcomptabs')
     console.log(this.RelatedComponentTabs)

    },
    AssignFieldOutput(){
      console.log(this.FieldFilter)
      let fieldobject = this.FieldFilter
      if(this.FieldFilter.InputType === 'Parent Field'){
        this.TempParentInput.MapType = 'Parent Field'
        this.FieldFilter.Input = []
        this.FieldFilter.Input.push(this.TempParentInput)
        this.FieldFilter.Input[0].ParentInput = Object.assign({}, this.TempParentInput)
        this.FieldFilter.Output = this.FieldFilter.Input[0].DisplayName
      }
      else{
        alert('this is something else mate')
        let inputarray = this.FieldFilter.Input.map(input => {
          // if(this.FieldFilter.InputType !== 'Custom Value'){
            return input.DisplayName
          // }
          // else{
          //   return input
          // }
          
        })
        let objectstring = '' + inputarray
        let finalstring = objectstring.split(',').join(' ')
        this.FieldFilter.Output = finalstring
      }

    },
    
      AddAutomationAction(){
        if(!this.EditedAutomation.AutomationActionLastInteger){
          this.EditedAutomation.AutomationActionLastInteger = 101
        }        
     
      let NewApprovalAction= {
          MethodName: 'New Action',
        ActionType: '',
        Record: '' ,
        MethodID: this.EditedAutomation.AutomationActionLastInteger+this.EditedAutomation.ID
      }
       if(!this.EditedAutomation.AutomationActions){
          this.EditedAutomation.AutomationActions = []
      }
      this.EditedAutomation.AutomationActions.push(NewApprovalAction)
      this.EditedAutomation.AutomationActionLastInteger = this.EditedAutomation.AutomationActionLastInteger-1+2
      this.RefreshAutoDialog()
    },
    RefreshAutoDialog(){
        this.AutomationDialog = false
      this.AutomationDialog = true
    },
      AddCondition(){
          if(this.EditedAutomation.Conditions.length === 0){
             
              this.EditedAutomation.LastStepNumber = 1000
          }
        
         let stepsuffix = this.EditedAutomation.LastStepNumber-1+2
      let stepnumberstring = stepsuffix+this.EditedAutomation.ID
      console.log(stepnumberstring)
      let stepnumber = Number(stepnumberstring)
      console.log(stepnumber)
      let NewStep = {
        Name: 'Name',
        StepNumber: stepnumberstring,
        Steps: [],
        ResultActions: []
      }
      // this.EditedAutomation.NewStep.StepNumber
      this.EditedAutomation.Conditions.push(NewStep)
      console.log(this.EditedAutomation.Conditions)
      this.RefreshAutoDialog()
      },
      UpdateRecord(automation,condition){
          console.log('changed')
          console.log(condition)
          let conditionobj = this.EditedAutomation.Conditions.find(obj => obj == condition)
        let index = this.EditedAutomation.Conditions.indexOf(conditionobj)
        
      },
      EditAutomation(auto){
        this.EditedAutomation = ''
        console.log('going to edit now')
        console.log(auto)
          this.EditedAutomation = auto
          if(!this.EditedAutomation.ApprovalButtonText){
            this.EditedAutomation.ApprovalButtonText = auto.Name
          }
          this.AutomationDialog = true
      },
      CancelAutomationDialog(){
          this.AutomationDialog = false
      },
      
      AddBuildAutomation(){
          this.LastAutomationInteger = this.LastAutomationInteger-1+2 
          let NewAutomation = {
              ID: this.LastAutomationInteger,
              Name: 'New Automation',
              AutomationActions: [],
              Conditions: []
          }
          this.BuildAutomations.push(NewAutomation)
          this.SaveAutomation()
          
          db.collection('SystemConfig').doc(process.env.VUE_APP_RA_SYSTEM_ID).update({
              LastAutomationInteger: this.LastAutomationInteger
          })
          
      },
    }
}
</script>

<style>

</style>
